.header-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.header-input {
  border: none;
  background-color: transparent;
  color: #333;
  font-weight: 500;
  text-align: center;
  width: 100%;
  resize: 'none';
}

.header-input:focus {
  outline: none;
  border-bottom: 2px dashed #cdcdcd;
  margin-bottom: -2px;
}

.remove-btn-wrapper {
  display: none;
  position: absolute;
  right: 0;
  width: 24px;
}

.header-wrapper:hover .remove-btn-wrapper {
  display: inline;
}

.label {
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
