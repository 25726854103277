.option {
  height: 60px;
  padding: 0 12px;
  cursor: pointer;
  margin-bottom: 4px;
}

.option:hover {
  border-radius: 2px;
  outline: 0;
}

.option-label {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.uniform-response {
  width: 18px;
  display: flex;
  align-items: center;
}

.custom-choice {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.custom-choice:focus {
  outline: none;
  border-bottom: 2px dashed #cdcdcd;
}

.radio {
  accent-color: black;
  border: none;
  zoom: 1.5;
  cursor: pointer;
}

.selected {
  background-color: #e6e8ed;
}
.selected .custom-choice {
  font-weight: 600;
}
