.grid-wrapper {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
}

.dk-input {
  border: none;
  background: 0 0;
  display: inline-block;
  zoom: 1;
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: black;
}

.dk-wrapper {
  cursor: pointer;
}
