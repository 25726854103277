.cell-wrapper {
  width: auto;
  padding: 0 4px;
}

.cell-label {
  padding: 13px 2px 19px 2px;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.cell-label:hover {
  background-color: #d9dce3;
}

.input-field {
  margin-top: 4px;
  height: 26px;
  padding: 5px 10px 7px 10px;
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid #616e90;
  font-size: 16px;
  color: black;
  margin-bottom: 0;
  display: inline-block;
}

.input-field:focus {
  outline: 0;
}

.input-field:focus:not(:active) {
  box-shadow: 0 0 0 4px #92dcff;
}
