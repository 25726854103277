.question {
  width: auto;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  border: none;
  font-weight: normal;
}

.question:focus {
  outline: none;
  border-bottom: 2px dashed #cdcdcd;
  margin-bottom: -2px;
}
