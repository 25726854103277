.qdfEditor {
  font-family: monospace;
}

.wrap {
  display: flex;
  flex-direction: row;
}

.container {
  flex: 1;
  max-width: 1020px;
  min-width: 300px;
  margin: 0 auto;
}

.widget {
  width: 100%;
}
