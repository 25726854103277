.cell-wrapper {
  width: auto;
}

.cell-label {
  padding: 13px 0 19px;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.cell-label:hover {
  background-color: #d9dce3;
}

.radio-wrapper {
  width: 18px;
  height: 18px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  accent-color: black;
  display: inline-block;
  zoom: 1;
  vertical-align: middle;
}

.radio {
  border: none;
  background: 0 0;
  display: inline-block;
  zoom: 1;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
