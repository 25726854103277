.dropdown-field {
  width: 192px;
  height: 44px;
  border-radius: 2px;
  color: #4a5878;
  padding: 4px;
}

.dropdown-field:hover {
  color: black;
  cursor: pointer;
  border-color: black;
}
