.panel {
  overflow: auto;
  height: calc(100vh - 85px);
  width: 250px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
}

.unsupported-option {
  display: none;
}
